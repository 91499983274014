
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Home',

    metaInfo: { title: 'Accueil' },

    extends: View,

    mixins: [
      LoadSections([
        'hero',
        'home-first',
        'features',
        'our-domains',
        'contact-us',
        'customer-reviews',
        'access-to-the-gallery',
        'info-alt',
        'map',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
